import VueRouter from "vue-router";

import Index from "@views/index.vue";
import Login from "@views/login.vue";
import NoFound from "@views/404.vue";
import Menu from "@views/menu.vue";

// 懒加载
const My = () => import ("@views/my/my.vue");
const MySecond = () => import ("@views/my/mySecond.vue");
const Private = () => import ("@views/policy/private.vue");
const PaypalAdvance = () => import ("@views/payment/paypalAdvance.vue");
const Paypal = () => import ("@views/payment/paypal.vue");
const Stripe = () => import ("@views/payment/stripe.vue");
const VivaWallet = () => import ("@views/payment/vivaWallet.vue");
const Checkout = () => import ("@views/checkout.vue");
const TrustPayment = () => import("@/views/payment/trustPayment.vue");
const TrustPaymentJs = () => import("@/views/payment/trustPaymentJs.vue");
const GuavaPay = () => import("@/views/payment/guavaPay.vue");

const Browse = ()=> import ("@views/browse/browse.vue");

const routes = [{
        path: "/",
        name: "Index",
        component: Index,
        meta: {
            keepAlive: false,
        },
    },
    {
        path: "/index",
        name: "Index",
        component: Index,
        meta: {
            keepAlive: false,
        },
    },
    {
        path: "/home",
        name: "Index",
        component: Index,
        meta: {
            keepAlive: false,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            noneHeader: false,
        },
    },
    {
        path: "/usercenter",
        name: "Usercenter",
        component: My,
        meta: {
            noneHeader: false,
            keepAlive: false,
        }
    },
    {
        path: "/my",
        name: "My",
        component: My,
        meta: {
            noneHeader: false,
            keepAlive: false,
        }
    },
    {
        path: "/editAddress",
        name: "EditAddress",
        component: () =>
            import ("@views/my/editAddress.vue"),
        meta: {
            noneHeader: false,
            keepAlive: false,
        },
    },
    {
        path: "/usercenter/myaccount",
        name: "myaccount",
        component: MySecond,
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/usercenter/address",
        name: "address",
        component: MySecond,
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/usercenter/orderhistory",
        name: "orderhistory",
        component: MySecond,
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/usercenter/points",
        name: "Points",
        component: () => import ("@/components/MyComps/Points.vue"),
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/usercenter/favourite",
        name: "Favourite",
        component: () => import ("@/components/MyComps/Favorite.vue"),
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/mySecond",
        name: "MySecond",
        component: MySecond,
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/browse",
        name: "Browse",
        component: Browse,
        meta: {
            keepAlive: true,
            noFixedHeader: true,
        }
    },
    {
        path: '/area-*',
        name: 'AreaSearch',
        component: Browse,
        meta: {
            keepAlive: true,
            noFixedHeader: true
        }
    },
    {
        path: '/menu-*',
        name: 'Menu',
        component: Menu,
        meta: {
            keepAlive: true,
            noFixedHeader: true
        }
    },
    {
        path: "/checkout",
        name: "Checkout",
        // component: Checkout,
        component: Checkout,
        meta: {
            keepAlive: true,
            noFixedHeader: true,
        }
    },
    {
        path: "/paypalAdvance",
        name: "PaypalAdvance",
        component: PaypalAdvance,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/vivaWallet",
        name: "vivaWallet",
        component: VivaWallet,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/paypal",
        name: "paypal",
        component: Paypal,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/trustPayment",
        name: "trustPayment",
        component: TrustPayment,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/guavaPayment",
        name: "guavaPayment",
        component: GuavaPay,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/trustPaymentJs",
        name: "trustPaymentJs",
        component: TrustPaymentJs,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/stripe",
        name: "Stripe",
        component: Stripe,
        meta: {
            keepAlive: false,
            noFixedHeader: true,
        },
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import ("@/views/about/index.vue"),
    },
    {
        path: "/cuisine",
        name: "Category",
        component: () =>
            import ("@/views/category/index.vue"),
    },
    {
        path: "/contact",
        name: "Contact",
        component: () =>
            import ("@/views/contact/index.vue")
    },
    {
        path: "/notFound",
        name: "404",
        component: NoFound,
    },
    {
        path: "/404",
        name: "404",
        component: NoFound,
    },
    {
        path: '*',
        name: 'Menu',
        component: Menu,
        meta: {
            keepAlive: true,
            noFixedHeader: true
        }
    },
    {
        path: "/Privacy-Policy",
        name: "Private",
        component: Private,
    },
    {
        path: "/page-privacy-policy",
        name: "PagePrivate",
        component: Private,
    },
    {
        path: '/orderSuccess',
        name: "orderSuccess",
        component: () =>
            import ("@/views/payment/orderSuccess.vue"),
    },
    {
        path: '/appPayCenter',
        name: "appPayCenter",
        component: () =>
            import ("@/views/payment/appPayCenter.vue"),
    },
    {
        path: '/payFail',
        name: "payFail",
        component: () =>
            import ("@/views/payment/payFail.vue"),
    },
    {
        path: "/privatePage",
        name: "Private",
        component: Private,
    },
    {
        path: "/faqs",
        name: "Faqs",
        component: () =>
            import ("@/views/policy/faqs.vue")
    },
    {
        path: "/termsConditions",
        name: "TermsAndConditions",
        component: () =>
            import ("@/views/policy/termsAndConditions.vue"),
    },
    {
        path: "/refundAndCancellations",
        name: "RefundAndCancellations",
        component: () =>
            import ("@/views/policy/refundAndCancellations.vue"),
    },
    {
        path: "/cookiePolicy",
        name: "CookiePolicy",
        component: () =>
            import ("@/views/policy/cookiePolicy.vue"),
    },
    {
        path: "/cookiePage",
        name: "CookiePage",
        component: () =>
            import ("@/views/policy/cookiePolicy.vue"),
    },
    
    {
        path: "/reviews",
        name: "Reviews",
        component: () =>
            import ("@/views/review.vue"),
    },
    {
        path: "/developer",
        name: "Developer",
        component: () => import ("@/views/develop/index.vue"),
        meta: {
            noneHeader: false,
            noFixedHeader: true,
        },
    },
];

function fixSticky() {
    // 单独给 html body 设置css 属性解决 sticky 问题
    document.querySelector('html').style.overflowX = 'unset';
    document.querySelector('body').style.overflowX = 'unset';
}

function fixOverfowX() {
    // 单独给 html body 设置css 属性解决 sticky 问题
    document.querySelector('html').style.overflowX = 'hidden';
    document.querySelector('body').style.overflowX = 'hidden';
}



const router =  new VueRouter({
    mode: "history",
    base: "",
    routes, // (缩写) 相当于 routes: routes
    // eslint-disable-next-line
    scrollBehavior(to, from, savedPosition) {
        fixOverfowX();
        if (to.path.includes("menu-") || to.path.includes("browse") || to.path.includes("mySecond")) {
            fixSticky(); // 部分路由横向滑动开放
        }

        // 处理滚动距离
        if (to.path.includes("menu-") || to.path.includes("checkout") || to.path.includes("orderSuccess")) {
            // document.body.scrollTop = document.documentElement.scrollTop = 100;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        } else if (to.path.includes("login")) {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        } else {
            // 始终滚动到顶部
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        return { top: 0 };
    },
});

// 设置description
function setMetaDescription(metaName,content) {
    try{
        if(!document) {
            return
        }
        var meta = document.querySelector(`meta[name="${metaName}"]`);
        if (meta) {
            meta.setAttribute('content', content);
        } else {
            meta = document.createElement('meta');
            meta.setAttribute('name', metaName);
            meta.setAttribute('content', content);
            document.head.appendChild(meta);
        }
    } catch(err){
        console.log(err,'set document attribute failed')
    }

}

router.beforeEach((to, from, next) => {

    // console.log(to,'to', document.title)

    if(to.path == '/Privacy-Policy') {
        document.title = 'Privacy Policy- All Eat App'
        setMetaDescription('description','This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.');
    } else if(to.path == '/about') {
        document.title = 'About Us - All Eat App'
        setMetaDescription('description',`In response to the current cost of living crisis, All Eat App online food ordering platform in the UK & Ireland, is committed to ensuring that takeaway food remains affordable for all. By charging 0% commission from our partner restaurants, we keep costs low for our partners, thus ensuring that you pay less for takeaway food. We are your ultimate destination for online ordering from a variety of local restaurants and takeaways, dedicated to offering unbeatable food, unbeatable price and unbeatable service. Why pay more when you can indulge in your favourite cuisines for less? Whether you're in the mood for Chinese, Indian, Thai, Pizza, Japanese, Korean, or kebabs, we've got you covered. The best part? You'll save money when you order from us compared to other food apps.`);
    } else if(to.path == '/contact') {
        document.title = 'Contact us - All Eat App'
        setMetaDescription('description',`Whether you have a question, a suggestion, or just want to say hello, we're here for you.`);
    } else if(to.path == '/cookiePolicy') {
        document.title = 'Cookies Policy- All Eat App'
        setMetaDescription('description',`We use cookies to make your experience on our site better and to improve the services we offer.`);
    } else if(to.path == '/cuisine' && to.query && to.query.cuisineName == 'Chinese') {
        document.title = 'Chinese Restaurants & Takeaways Near Me- Order food online on All Eat'
        setMetaDescription('description',`Find the best Chinese restaurants and takeaways near you on All Eat. Order now and enjoy hot & tasty food delivered to your door.`);
    } else if(to.path == '/cuisine' && to.query && to.query.cuisineName == 'Pizza') {
        document.title = 'Pizza Restaurants & Takeaways Near Me- Order food online on All Eat'
        setMetaDescription('description',`Find the best Pizza restaurants and takeaways near you on All Eat. Order now and enjoy hot & tasty food delivered to your door.`);
    } else if(to.path == '/cuisine' && to.query && to.query.cuisineName == 'Indian') {
        document.title = 'Indian Restaurants & Takeaways Near Me- Order food online on All Eat'
        setMetaDescription('description',`Find the best Indian restaurants and takeaways near you on All Eat. Order now and enjoy hot & tasty food delivered to your door.`);
    } else if(to.path == '/faqs') {
        document.title = 'Customer FAQs- All Eat App'
        setMetaDescription('description',`Get answers for the most commonly asked questions about orders, terms and conditions and payments in All Eat FAQ section`);
    } else if(to.path == '/usercenter/orderhistory') {
        document.title = 'Your All Eat Order History'
        setMetaDescription('description',`Get all your previous orders information here. Login and get the previous order details `);
    } else if(to.path == '/refundAndCancellations') {
        document.title = 'Refunds & Cancellations- All Eat App'
        setMetaDescription('description',`If you're not satisfied with your takeaway order or service and would like a refund, simply reach out to us through our live chat or email support. We're here to help!`);
    } else if(to.path == '/termsConditions') {
        document.title = 'All Eat App Terms & Conditions'
        setMetaDescription('description',`Please read the terms and conditions below when using the All Eat website and application.`);
    } else if(to.path == '/usercenter') {
        document.title = 'User Dashboard- All Eat App'
        setMetaDescription('description',`View full order history including your past order details and login details on your All Eat dashboard`);
    } else {
        document.title = 'Order Takeaway Food Delivery Online in UK with All Eat | Find Top Takeaways & Restaurants near me'
        setMetaDescription('description',`Order food online from restaurants and takeaways near you with All Eat- UK's premiere online food ordering platform. Get delicious cuisines delivered to you in no time with our fast, secure & affordable takeaway food delivery portal.`);

    }
    
   
    // 确定路由是否应该继续
    next(); // 继续至下一个路由
  });

export default router;