<template>
  <div :class="['fix-header', { 'no-fixed': noFixedHeader }]">
    <header>
      <div class="logo-Box">
        <img
          class="logo-img"
          src="@assets/logo.png"
          alt="logo"
        />
        <span class="logo-font">All</span>
        <span class="logo-font eat">Eat</span>
        <a href="/index"></a>
      </div>

      <span style="flex: 1"></span>
      <a class="header-btn" href="/"  @click="closePop">Home</a>
      <a class="header-btn" href="/about"  @click="closePop">About Us</a>
      <a class="header-btn" @click="goLogin" v-if="!token">
        <i class="el-icon-user-solid"></i>
        Login | Register
      </a>
      <a class="header-btn" href="/usercenter"  @click="closePop" v-else>
        <i class="el-icon-user-solid" style="color: #aa0bc0;"></i>
        User Center
      </a>
      <a class="header-btn" href="/usercenter/orderhistory" @click="closePop" v-if="token">
        <i class="el-icon-s-order" style="color: #26d07c;"></i>
        My Orders
      </a>
      <a class="header-btn" href="/faqs" @click="closePop">
        <i class="el-icon-question"></i>
        FAQ's
      </a>
      <!-- <span class="header-btn" @click="goHome">Home</span>
      <span class="header-btn" @click="aboutUs">About Us</span>
      <span class="header-btn" @click="goLogin" v-if="!token">
        <i class="el-icon-user-solid"></i>
        Login | Register
      </span>
      <span class="header-btn" @click="goUserPage" v-else>
        <i class="el-icon-user-solid" style="color: #aa0bc0;"></i>
        User Center
      </span>
      <span class="header-btn" @click="goOrderPage" v-if="token">
        <i class="el-icon-s-order" style="color: #26d07c;"></i>
        My Orders
      </span> -->
      <!-- <span class="header-btn btn-green" @click="becomePart"
        >Become Partner</span
      > -->
      <!-- <span class="header-btn" style="position: relative">
        EN
        <i class="el-icon-arrow-down"></i>
      </span> -->
      <!-- <span class="header-btn" @click="goFaqs">
        <i class="el-icon-question"></i>
        FAQ's
      </span> -->
      <el-popover
        placement="bottom"
        width="180"
        v-model="showPop"
        trigger="manual"
      >
        <ul class="menu-box">
          <!-- <li @click="goHome"> -->
          <li>
            <a href="/"  @click="closePop">Home</a>
          </li>
          <li>
          <!-- <li @click="aboutUs"> -->
            <a href="/about" @click="closePop">About Us</a>
          </li>
          <li v-if="!token">
          <!-- <li @click="goLogin" v-if="!token"> -->
            <a @click="goLogin" >
              <i class="el-icon-user-solid"></i>
              Login | Register
            </a>
          </li>
          <li  v-else>
          <!-- <li @click="goUserPage" v-else> -->
            <a href="/usercenter"  @click="closePop">
              <i class="el-icon-user-solid" style="color: #aa0bc0"></i>
              User Center
            </a>
          </li>
          <li  v-if="token">
          <!-- <li @click="goOrderPage" v-if="token"> -->
            <a href="/usercenter/orderhistory" @click="closePop">
              <i class="el-icon-s-order" style="color: #26d07c;margin-right: 19px;"></i>
              My Orders
            </a>
          </li>
          <!-- <li class="btn-green" @click="becomePart">Become Partner</li> -->
          <!-- <li style="position: relative">
            EN
            <i class="el-icon-arrow-down"></i>
          </li> -->
          <li>
          <!-- <li @click="goFaqs"> -->
            <a href="/faqs" @click="closePop">
              <i class="el-icon-question"></i>
              FAQ's
            </a>
          </li>
        </ul>

        <div slot="reference">
          <span class="menu-icon" @click="showPop=true" @touchstart="showPop=true">
            <i class="el-icon-s-unfold"></i>
          </span>
        </div>
      </el-popover>
    </header>

    <div class="cover-box" v-if="showPop" @touchstart="showPop = false"></div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    noFixedHeader: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      token: "",
      showPop: false,
    };
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.$bus.$on('login', () => {
      setTimeout(() => {
        this.token = localStorage.getItem("token");
      }, 100);
    });
    this.$bus.$on('logout', () => {
      this.token = localStorage.getItem("token");
    });
    // window.onscroll = function(e) {
    //   console.log(e, '滚动了')
    // }
  },
  methods: {
    closePop() {
      this.showPop = false;
      console.log( this.showPop,"调用了")
    },
    goHome() {
      // if(localStorage.getItem('searchAddress')) {
      //   this.$router.push({
      //     path: '/browse'
      //   })
      // } else {
      //   this.$router.replace({
      //     path: "/",
      //   });
      // }
      this.$router.replace({
        path: "/",
      });
      this.closePop();
    },
    goUserPage() {
      this.$router.push({
        path: "/usercenter",
      });
      this.closePop();
    },
    goOrderPage() {
      this.$router.push({
        path: "/usercenter/orderhistory",
      });
      this.closePop();
    },
    goLogin() {
      this.$router.push({
        path: "/login",
        query: {
          a: 10,
        },
      });
      this.closePop();
    },
    aboutUs() {
      this.$router.push({
        path: "/about",
      });
      this.closePop();
    },
    goFaqs() {
      this.$router.push({
        path: "/faqs",
      });
      this.closePop();
    },
    becomePart() {
      window.open("https://restaurants.alleatapp.com");
      // this.$router.push({
      //   path: "/contact",
      // });
      this.closePop();
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-header {
  height: 93px;
}
.no-fixed {
  header {
    position: relative !important;
  }
}
.cover-box {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 200vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
}
header {
  padding: 0 4%;
  display: flex;
  align-items: center;
  height: 96px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;

  .logo-Box {
    display: flex;
    align-items: center;
    position: relative;
    a {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 20;
    }
  }
  .logo-img {
    height: 70px;
    width: 72px;
    margin-right: 20px;
  }

  .logo-font {
    font-size: 30px;
    font-weight: bold;
    color: #aa0bc0;
  }

  .eat {
    margin-left: 8px;
    color: #aa0bc0;
    color: #26d07c;
  }

  .header-btn {
    margin-left: 16px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 46px;
    padding: 0 20px;
    border-radius: 5px;
    display: inline-flex;
    line-height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: #222;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    white-space: nowrap;
    i {
      font-size: 20px;
      margin-right: 10px;
    }

    .el-icon-arrow-down {
      margin-left: 10px;
      font-weight: 700;
      color: #000;
      margin-right: 0;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 9px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
  }

  .menu-icon {
    display: none;
  }

  .btn-green {
    background: #09ca6a;
    color: #fff;
  }
}

.menu-box {
  display: flex;
  flex-direction: column;

  li {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 46px;
    padding: 0 20px;
    border-radius: 5px;
    display: inline-flex;
    line-height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    color: #222;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    margin-top: 12px;
    white-space: nowrap;
    i {
      font-size: 20px;
      margin-right: 10px;
    }

    .el-icon-arrow-down {
      margin-left: 10px;
      font-weight: 700;
      color: #000;
      margin-right: 0;
    }
  }
  .btn-green {
    background: #09ca6a;
    color: #fff;
  }
}

// 处理兼容
@media screen and (max-width: 950px) {
  .fix-header {
    height: 65px;
  }
  .no-fixed {
    header {
      // position: fixed!important;
    }
  }
  header {
    height: 65px;
    .logo-img {
      height: 42px;
      width: 43px;
      margin-right: 15px;
    }

    .logo-font {
      font-size: 20px;
    }

    .eat {
      margin-left: 4px;
    }
    .header-btn {
      display: none;
    }
    .menu-icon {
      display: inline-flex;
      font-size: 26px;
      color: #000;
    }
  }
}
</style>
