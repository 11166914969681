<template>
  <div class="my" >
    <div class="match-box" >
      <div class="left-box">
        <div class="break-box" >
            <div class="break">
                <i class="el-icon-back" @click="$router.go(-1)"></i>
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>
                          <span style="cursor:default;font-weight: 600;color:#09CA6A;font-size: 16px;">My account</span>
                      </el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
          </div>
          <div class="profile-box" >
            <el-upload
              class="avatar-uploader"
              action="https://nweb.alleatapp.com/base/merchant/food/upload-files"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="handleAvatarSuccess">
              <div class="edit-img">
                <i class="el-icon-plus"></i>
              </div>
              
              <i class="el-icon-loading" v-if="iconLoading"></i>
              <img v-else-if="userInfo.avatar" :src="userInfo.avatar" alt="">
              <img v-else src="@assets/images/profile_default.png" alt="">
            </el-upload>
            
          </div>
          <h3 class="user-name" >{{ userName }}</h3>
          <h4 class="user-email" >{{ userInfo.emailAddress }}</h4>
          <div class="tab-box">
            <ul>
              <li v-for="(tab,index) in tabList" :class="['tab-item', {'tab-active': currentTab==index && !isMobile}]" 
                @click="chooseTab(tab, index)" :key="tab.name">
                <i :class="['tag-icon', tab.icon]" v-if="tab.icon" ></i>
                <span class="name" >{{ tab.name }}</span>
                <i class="el-icon-right" ></i>
              </li>
              <el-popconfirm
                title="Are you sure to delete your account?"
                @confirm="deleteAccount"
              >
                <li slot="reference" class="tab-item logout-item" style="background: #2f3542;" >
                  <span class="name" >Delete Account</span>
                </li>
              </el-popconfirm>
              <li class="tab-item logout-item" @click="logout" >
                <span class="name" >Logout</span>
                <!-- <i class="el-icon-right" ></i> -->
              </li>
            </ul>
          </div>
      </div>

      <div class="right-box">
        <div class="input-box">
            <img style="cursor: pointer;" @click="goBrowse(true)" src="@/assets/icon/input_search.png" alt="">
            <input type="text" v-model="keyword" @keyup.enter="goBrowse(true)" placeholder="Enter your favorite restaurant name">
            
        </div>
        
        <!-- <div class="order-tip-box" v-if="tabList[currentTab].components=='Orders'">
          <h1 class="my-title" >We Are Making</h1>
          <div class="my-desc" >
            Cusine king is Preparing your order <br />
            Estimate time arrival 45-60 minutes
          </div>
          <div  class="my-order-box">
            <h2>Your Order</h2>
            <div class="order-card" >
              <div class="card-left flex-col-center ">
                  <img :src="'https://www.alleatapp.com/upload'+item.merchantLogo" alt="">
                  <div :class="['status-tag', ('status-'+item.status)]" >{{ item.status }}</div>
              </div>
              <div class="card-center">
                  <div class="name">{{ item.merchantName }}</div>
                  <div class="desc" >
                      <span>{{ item.items.split(',').length }} items for ￡{{ item.price }}</span> 
                      <span class="point"></span>
                      <span style="margin-left:5px;">{{ item.orderTime }}</span>
                      <span style="margin-left: 15px;" class="under-line">View receipt</span>
                  </div>
              </div>
              <div class="card-right flex-col-center " >
                  <div class="btn" @click="goMerchant(item)">Visit Store</div>
                  <div class="btn" @click="goMerchant(item)">Order Again</div>
                  <div class="btn" @click="paynow(item)">Pay now</div>
              </div>
            </div>
          </div>
        </div> -->

        <h1 class="my-title" v-if="tabList[currentTab].name!='Points'">{{ tabList[currentTab].name }}</h1>
        <div class="my-desc" >
          <!-- Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do <br />
          eiusmod tempor incididunt utolme. -->
        </div>
        <component :is="tabList[currentTab].components"></component>
      </div>
    </div>
    
   <!-- 回到顶部按钮 -->
   <el-backtop :bottom='128' :right='60'>
      <i class="el-icon-caret-top"></i>
    </el-backtop>

  </div>
</template>

<script>
/* eslint-disable */ 
import debounce from "@/utils/debounce.js";
import { postGateway } from '@/request';
import Favorite from "@/components/MyComps/Favorite.vue";
import Account from "@/components/MyComps/Account.vue";
import Orders from "@/components/MyComps/Orders.vue";
import Address from "@/components/MyComps/Address.vue";
import Points from "@/components/MyComps/Points.vue";
import bus from '@/utils/bus';
export default {
  name: "NotFound",
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  components: {
    Favorite,
    Account,
    Orders,
    Points,
    Address
  },
  data() {
    return {
      transType: 1,
      keyword: "",
      resultList: [],
      loading: false,
      clientId: null,
      userInfo: {
        contactPhone: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        nickName: '',
      },
      iconLoading: false,
      currentTab: 2,
      tabList: [
        {
          name: 'My Account',
          icon: 'el-icon-user-solid',
          components: 'Account',
          mobilePath: '/usercenter/myaccount',
        },
        // {
        //   name: 'Edit Profile',
        //   icon: 'el-icon-s-tools',
        //   components: 'Account',
        // },
        {
          name: 'Delivery Address',
          icon: 'el-icon-location',
          components: 'Address',
          mobilePath: '/usercenter/address',
        },
        {
          name: 'Order History',
          icon: 'el-icon-s-order',
          components: 'Orders',
          mobilePath: '/usercenter/orderhistory',
        },
        {
          name: "FAQ's",
          icon: 'el-icon-question',
          components: 'orders',
          path: '/faqs'
        },
        {
          name: 'Privacy Policy',
          icon: 'el-icon-info',
          components: 'orders',
          path: '/Privacy-Policy'
        },
        {
          name: 'Points',
          icon: 'el-icon-star-on',
          components: 'Points',
          mobilePath: '/usercenter/points',
        },
        {
          name: "Favourite",
          icon: 'el-icon-s-cooperation',
          components: 'Favorite',
          mobilePath: '/usercenter/favourite',
        }
      ]
    };
  },
  created() {
    this.clientId = localStorage.getItem('clientId');
    this.getUserInfo();
    if(this.isMobile && this.$route.query.type == 'orderDone') {
      this.$router.push({
        path: '/mySecond',
        query: {
          name: 'Order',
          index: 2
        }
      })
    }
  },
  mounted() {

  },
  computed: {
    userName() {
      return (this.userInfo.firstName +' ' + this.userInfo.lastName).trim() || this.userInfo.nickName || 'AllEat User'
    },
    isMobile() {
      return window.innerWidth < 950
    }
  },
  methods: {
    deleteAccount() {
      postGateway({
        url: '/customer/atClient/close',
        data: {}
      }).then(res=>{
        console.log(res,'res');
        this.logout();
      })
      .catch(err=>{
        this.$message.error('Error happend.');
      })
    },
    chooseTab(tab, index) {
      this.currentTab = index;
      if(tab.path) {
        this.$router.push({
          path: tab.path
        })
        return
      }
      if(this.isMobile) {  // 移动设备需要跳转到新页面
        this.$router.push({
          path: tab.mobilePath?tab.mobilePath:'/mySecond',
          query: {
            ...tab,
            index
          }
        })
      }
    },
    goBrowse() {
      this.$router.push({
        path: '/browse',
        query: {
          searchName: this.keyword
        }
      })
    },
    logout() {
      localStorage.clear();
      bus.$emit('logout')
      this.$router.replace('/')
      location.reload()
    },
    beforeUpload() {
      this.iconLoading = true;  
    },
    handleAvatarSuccess(res) {
      console.log(res.data, '头像上传成功')
      let address = res.data[0].fileAddress;
			let datePath = res.data[0].datePath;
			this.userInfo.avatar = this.$imgBaseUrl + datePath + '/'+  address;
      this.submit();
    },
    submit() {
      let {avatar,nickName,emailAddress,contactPhone} = this.userInfo;
			console.log(avatar,'头像地址')
			
			if(!emailAddress.trim()) {
				this.$message.toast('Check your Email');
				return
			}
			
			let param = {
				clientId: localStorage.getItem('clientId'),
				avatar,nickName,emailAddress,contactPhone
			}
      postGateway({
          url: '/customer/atClient/savePersonalInfo',
          method: 'POST',
          data: param
      }).then(()=>{
          this.iconLoading = false;
      })
      .catch(err=>{
          this.iconLoading = false;
          this.$message.error(err.msg)
      })
    },
    getNowOrder() {

    },
    getUserInfo() {
      this.iconLoading = true;
      postGateway({
        url: `/customerApp/center/getDetail`,
        method: 'GET',
        data: {
            clientId: this.clientId
        }
      }).then(res=>{
        this.iconLoading = false;
        this.userInfo = res.data;
        console.log(res,'用户信息')
      })
      .catch(err=>{
        this.iconLoading = false;
        console.log(err)
      })
    },
    inputNow() {
      this.resultList = [];
      this.loading = true;
      debounce(() => {
        if (this.keywords.trim().length < 3) return;
        postGateway({
          method: "GET",
          url: "/customerApp/index/getPreKeyword",
          data: { keyword: this.keywords },
        })
          .then((res) => {
            console.log(res)
          })
          .catch(() => {
            
          });
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
.my {
  box-sizing: border-box;
  background: #f5f5f5;
  .match-box {
    display: flex;
    margin: 0 auto;
    min-height: 100vh;
    width: 1450px;
  }

  .left-box {
    position: relative;
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    padding: 0;

    .break-box {
        box-sizing: border-box;
        padding: 20px 4%;
        .break {
            height: 380px;
            height: 44px;
            display: inline-flex;
            align-items: center;
            padding: 20px;
            border-radius: 22px;
            background-color: #0A191E;
            color: #FFFFFF;
            .el-icon-back {
                color: #2680ED;
                font-size: 22px;
                margin-right: 10px;
            }
            :deep(.el-breadcrumb__inner) {
                color: #fff;
            }
            :deep(.el-breadcrumb__separator) {
                color: #fff;
            }
        }
    }

    .profile-box {
        height: 150px;
        width: 150px;
        border-radius: 75px;
        // overflow: hidden;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
            height: 144px;
            width: 144px;
            border-radius: 77px;
        }

        .edit-img {
          position: absolute;
          bottom: 15px;
          right: 0px;
          height: 34px;
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $mainColor;
          border-radius: 17px 17px 17px 2px;
          cursor: pointer;
          i {
            color: #fff;
            font-weight: 600;
            font-size: 19px;
          }
        }
    }
    .user-name {
        height: 30px;
        font-size: 30px;
        text-align: center;
        color: #000;
        margin: 8px 0;
        font-weight: 400;
    }
    .user-email {
        height: 25px;
        color: #000;
        font-size: 20px;
        text-align: center;
        margin: 0px;
    }

    .tab-box {
        margin: 0px 10px 0;
        // background: #FFFFFF;
        background: #f5f5f5;
        border-radius: 10px;
        padding: 20px;

        ul {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;

          i {
            margin: 0;
            font-size: 22px;
          }

          .logout-item {
            background: #8d8c8c;
            color: #fff;
            margin-top: 12px;
            padding: 0;
            .name {
              color: #fff;
            }
          }

          li {
            height: 58px;
            margin: 10px 0;
            display: flex;
            align-items: center;
            padding: 0 10px 0 20px;
            // border-radius: 8px;
            border-radius: 30px;
            cursor: pointer;
            transition: all 0.2s ease-in-out 0s;
            // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
            background: #ffffff;
            &:hover {
              background-color: rgba(0, 0, 0, 0.15);
            }

            .name {
              flex: 1;
              text-align: center;
              font-weight: 600;
              font-size: 18px;
              color: #767676;
            }
          }
          .tab-active {
            box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
            background-color: $mainColor;
            color: #fff;
            &:hover {
              background-color: $mainColor;
            }
            .name {
              color: #fff;
            }
          }
        }


    }

  }

  .right-box {
    flex: 1;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;

    .my-title {
      text-align: center;
      color: #000;
      font-size: 50px;
      font-family: 'Inter', sans-serif;
      font-family: 'Source Sans Pro', sans-serif;
    }
    .my-desc {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #656565;
      font-family: 'Inter', sans-serif;
    }

    .order-tip-box {
      .my-title {
        margin-bottom: 0;
      }
      .my-order-box {
        margin-top: 20px;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        border-radius: 16px;
        height: 240px;
        background: #fafafa;
        padding: 20px 40px;

        h2 {
          font-size: 26px;
          display: inline-block;
          color: #000;
          border-bottom: 2px solid #000;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          margin: 0;
        }
        
        .order-card {
            display: flex;
            white-space: wrap;
            word-break: normal;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
            margin-bottom: 10px;
            .card-left {
                padding: 0;
                margin-right: 20px;
                img {
                    height: 110px;
                    width: 110px;
                    border-radius: 8px;
                }
                .status-tag {
                    margin-top: 8px;
                    border-radius: 4px;
                    padding: 6px 0;
                    width: 110px;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    background-color: $greenColor;
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                }
                .status-unpaid {
                    background-color: #FF8000;
                }
                .status-cancel {
                    background-color: #FF1F00;
                }
                .status-initial_order {
                    background-color: #8F9698;
                }
            }
            .card-center {
                flex: 1;
                .name {
                    padding-top: 10px;
                    font-size: 22px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 6px;
                }
                .desc {
                    color: #000;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    font-size: 15px;

                    .point {
                        display: inline-block;
                        height: 4px;
                        width: 4px;
                        border-radius: 2px;
                        background-color: #000;
                        margin-left: 8px;
                    }
                    .under-line {
                        position: relative;
                        bottom: 1px;
                        font-size: 17px;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                .rate-box {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    :deep(.el-rate__icon) {
                        font-size: 22px;
                    }

                    .review-btn {
                        color: #8F9698;
                        margin-left: 10px;
                        font-size: 18px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .img-btn {
                        height: 24px;
                        width: 24px;
                        margin-left: 10px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            .card-right {
                padding-right: 40px;
                .btn {
                    height: 48px;
                    width: 200px;
                    background-color: $greenColor;
                    font-weight: bold;
                    color: #fff;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all .2s ease;
                    &:hover {
                        background-color: #08b25d;
                    }
                }
            }
        }

      }
    }

    .input-box {
        height: 56px;
        display: flex;
        align-items: center;
        // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        // border-radius: 4px;
        // padding-right: 40px;
        border-radius: 30px;
        padding-left: 25px;
        background: #ffffff;
        input {
            flex: 1;
            height: 100%;
            padding-left: 20px;
            margin-right: 20px;
            font-size: 19px;
            font-weight: 600;
            color: #000;

            &::placeholder {
                font-weight: 500;
                color: #333;
            }
        }

        img {
            height: 40px;
            width: 40px;
        }
    }
  }

  .back-btn {
    position: relative;
    background: #fff;
    height: 36px;
    border-radius: 18px;
    padding: 0 20px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 30px 6% 0;
  }
}

@media screen and (max-width: 950px) {
  .my {
    .match-box {
      width: 100vw;
      flex-direction: column;
      .left-box {
        width: 100%;
        margin-right: 0;
        .break-box {
          display: none;
        }
        .profile-box {
          zoom: 0.7;
        }
        .user-name {
          font-size: 18px;
        }
        .user-email {
          margin-top: -10px;
          font-size: 16px;
        }
      }
      .right-box {
        display: none;
      }
      .tab-box {
        .tab-item {
          height: 48px;
          margin: 5px 0;
          i {
            color: #aa0bc0;
          }
          .name {
            color: #000;
          }
        }
      }
    }
  }
}

</style>
